<template>
  <div data-sel="page-api">
    <CContainer fluid>
      <CJumbotron>
        <h1 class="display-3">Core API</h1>
        <p class="lead">neuraum core API</p>
        <p>Playground for Graph QL to the neuraum core.</p>
        <CButton color="primary" to="apis/core">Playground</CButton>
      </CJumbotron>
      <CJumbotron>
        <h1 class="display-3">Contracts</h1>
        <p class="lead">neuraum contracts API</p>
        <p>Manage account owners, debitors, clients and their contracts with neuraum.</p>
        <CButton color="primary" to="apis/contracts">Swagger UI</CButton>
      </CJumbotron>
      <CJumbotron>
        <h1 class="display-3">Locations</h1>
        <p class="lead">neuraum locations API</p>
        <p>The API provides postal-codes with references to administrative regions.</p>
        <CButton color="primary" to="apis/locations">Swagger UI</CButton>
      </CJumbotron>
    </CContainer>
  </div>
</template>

<script>
export default {
name: "Home"
}
</script>

<style scoped>

</style>